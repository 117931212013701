import React from 'react';
import Button from 'antd-mobile/es/button';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import ActionType from '@/actions/action-type';
import Icon, { IconType } from '@/components/Icon';
import { parseRouteParams, createRouteUrl, getDomain, getPlatformPayInfo, findNode } from '@/utils';
import { IObject } from '@/components/bm-common';
import PayInfo from './PayInfo';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import Layout from '@/components/Layout/Layout';
import { IOrderInfo, ISaleTransaction } from '@/lib/order-builder/models/OrderEntity';
import Constants from '@/constants';
import paths from '@/routes/paths';
import TabsOption from '@/actions/tabs-option';
import { PayResultsPageStyled } from './styled';
import { prefix } from '.';
import { PayTypes } from '@/lib/payment';
import { IShareCartInfo } from '@/utils/share-util';
import { getI18nText } from '@/utils/app';
import dayjs from 'dayjs';
import BottomBar from '@/components/BottomBar/BottomBar';
import { IStore, IStoreTable } from '@/actions/store-action';
import ShippingMethod from '@/lib/order-builder/enums/ShippingMethod';

export interface IPayResultsPageProps extends IBasePageProps {}
export interface IPayResultsPageState {
  orderInfo?: IOrderInfo;
  realDomain?: string;
  showBottomBar: boolean; // 是否显示分享、删除
  shareData: IShareCartInfo; 
  store?: IStore;
}

class PayResultsPage extends BasePage<IPayResultsPageProps, IPayResultsPageState> {
  routeParams: IObject = {};

  state: IPayResultsPageState = {
    orderInfo: undefined,
    realDomain: undefined,
    showBottomBar: false,
    shareData: {
      data: [],
      storeId: '',
    },
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.darkBackgroundColor) {
      setScreenColor(appTheme.darkBackgroundColor);
    }
    super.componentDidMount();

    this.routeParams = parseRouteParams(this.props);
    const { dispatch, reducersState } = this.props;
    const { fromUrlPayData } = reducersState.pay;

    const { payInfo } = this.routeParams;
    let payStoreID; // 下单时真正的storeID

    if (payInfo && payInfo.startsWith(Constants.PLATFORMPAYINFO)) {
      const payInfoObj = getPlatformPayInfo(payInfo);
      console.info(payInfoObj);
      payStoreID = payInfoObj.storeID;
      this.setState({realDomain: payInfoObj.domain})
    }
    let shareData: IShareCartInfo = {
      data: [],
      storeId: '',
    };
    const { userInfo } = reducersState.user;
    const { platformAppSettings } = reducersState.app;
    shareData = {
      data: [],
      customer: userInfo ? userInfo.name : '',
      email: userInfo ? userInfo.email : '',
      storeId: '',
      referenceID: '',
    }

    if (this.routeParams.orderNumber && this.routeParams.storeSlug) {
      dispatch({
        type: ActionType.QUERY_ORDER_INFO,
        storeSlug: this.routeParams.storeSlug,
        orderNumber: this.routeParams.orderNumber,
        fromUrlPayData,
        refresh: true,
        storeID: payStoreID,
        callback: orderInfo => {
          if (orderInfo && platformAppSettings && platformAppSettings.suppliers) {
            if (this.routeParams.buyerStoreID) {
              dispatch({
                type: ActionType.QUERY_STORE_BY_ID,
                storeID: this.routeParams.buyerStoreID,
                callback: (store, storeConfig) => {
                  if (store) {
                    this.setState({
                      store,
                    })
                  }
                }
              });
            }
            orderInfo.orderEntities.forEach(item => {
              if (item.lineItems.length > 0) {
                dispatch({
                  type: ActionType.QUERY_STORE_BY_ID,
                  storeID: item.storeID,
                  callback: (store: any) => {
                    if (store) {
                      shareData.data.push({
                        storeName: getI18nText(store, 'title', reducersState.app.language) || store.name,
                        records: item.lineItems,
                        deliveryDate: dayjs(item.deliveryDate).format('YYYY-MM-DD'),
                        total: item.initialTotal || 0,
                        orderTime: orderInfo ? dayjs(orderInfo.orderTime).format('YYYY-MM-DD') : '',
                        remark: item.note,
                      })
                      shareData.storeId = item.storeID;
                      shareData.referenceID = !orderInfo.superOrder ? `${orderInfo.referenceID || ''}` : '';
                    }
                  }
                });
              }
            })
          }
          this.setState({
            orderInfo,
            shareData,
          })
        }
      });
    }
  }

  handleBackHome = () => {
    const { currentApp, payInfo, storeSlug } = this.routeParams;
    const { orderInfo } = this.state;
    const { dineInParty } = orderInfo || {};
    const { slugRefTables } = this.props.reducersState.store;
    const tables = slugRefTables[storeSlug] || [];
    const payInfoObj = getPlatformPayInfo(payInfo);
    const mode: string | undefined = payInfoObj.mode || undefined;
    let queryStr: string | undefined = undefined;
    const queryObj: IObject = {};
    if (mode === 'D') {
      queryStr = 'mode=dedicated';
      queryObj['mode'] = 'dedicated';
      if (dineInParty && dineInParty.tableID) {
        const table: IStoreTable | undefined = findNode(tables, 'id', dineInParty.tableID);
        if (table) {
          queryStr += `&uuid=${table.uuid}`;
          queryObj['uuid'] = table.uuid;
        }
      }
    }
    if (currentApp === '1' || currentApp === 1) {
      this.handleGotoRoute({
        path: paths.MENU_LIST,
        pathParams: {
          storeSlug: storeSlug,
        },
        search: queryStr,
      });
    } else {
      const { platformHost, isLocalhost } = getDomain(this.state.realDomain);
      const protocol = `http${isLocalhost ? '' : 's'}:`;
      window.location.href = `${protocol}//${platformHost}${createRouteUrl(
        paths.MENU_LIST,
        {
          storeSlug,
          query: queryObj,
        },
      )}`
    }
  }

  handleOrderDetail = () => {
    const { orderInfo } = this.state;
    const { orderNumber, currentApp } = this.routeParams;

    const { platformHost, isLocalhost } = getDomain(this.state.realDomain);
    const protocol = `http${isLocalhost ? '' : 's'}:`;
    const { storeSlug } = orderInfo || {};
    if (storeSlug && orderNumber) {
      if (currentApp === '1' || currentApp === 1) {
        this.handleGotoRoute({
          path: paths.ORDER_DETAIL,
          pathParams: {
            storeSlug,
            orderNumber,
            suborderNumber: orderNumber,
            from: 'records',
          },
          clearPaths: true,
        });
      } else {
        window.location.href = `${protocol}//${platformHost}${createRouteUrl(
          paths.ORDER_DETAIL,
          {
            storeSlug,
            orderNumber,
            suborderNumber: orderNumber,
            from: 'records',
          },
        )}`;
      }
    } else {
      this.handleBackHome();
    }
  }

  handleOrders = () => {
    const { orderInfo } = this.state;
    const { orderNumber, currentApp } = this.routeParams;

    const { platformHost, isLocalhost } = getDomain(this.state.realDomain);
    const protocol = `http${isLocalhost ? '' : 's'}:`;
    const { storeSlug } = orderInfo || {};
          
    if (storeSlug && orderNumber) {
      if (currentApp === '1' || currentApp === 1) {
        this.handleGotoRoute({
          path: paths.RECORDS,
          pathParams: {
            storeSlug,
            orderNumber,
          },
          clearPaths: true,
        });
      } else {
        window.location.href = `${protocol}//${platformHost}${createRouteUrl(
          paths.RECORDS,
          {
            storeSlug,
            orderNumber,
          },
        )}`;
      }
    } else {
      this.handleBackHome();
    }
  }

  getSaleTransactions = () => {
    const { reducersState } = this.props;
    const { orderInfo } = this.state;

    let saleTransactions: ISaleTransaction[] = [];

    if (orderInfo) {
      saleTransactions = orderInfo.saleTransactions.filter(item => item.success !== false);
    }
    const { payMethods } = reducersState.pay;

    saleTransactions.forEach(item => {
      if (item.paymentMethod === 16) {
        const payMethod: any = payMethods.find(_item => _item.type === PayTypes.STORE_CREDIT || _item.type === PayTypes.ANONYMOUS_GIFT_CARD);
        if (payMethod) {
          item.balance = Number(payMethod.balance) - Number(item.amount)
        }
      }
    })
    return saleTransactions;
  }

  getPayMethod = (method: PayTypes) => {
    const paymentMethodRef: IObject = {
      [PayTypes.CASH_ON_DELIVERY]: 1,
      // [IconType.OE_PAY]: 3,
      // [IconType.CREDIT_CARD]: 4,
      // [IconType.VISA_CARD]: 4,
      // [IconType.MASTER_CARD]: 4,
      [PayTypes.AMEX]: 4,
      [PayTypes.MASTER]: 4,
      [PayTypes.VISA]: 4,
      [PayTypes.CREDIT_CARD]: 4,
      [PayTypes.UNION_PAY]: 4,
      [PayTypes.ANONYMOUS_GIFT_CARD]: 16,
      [PayTypes.STORE_CREDIT]: 16,
      [PayTypes.WECHAT_PAY]: 17,
      [PayTypes.APPLE_PAY]: 18,
      [PayTypes.ALIPAY]: 19,
      [PayTypes.ALIPAY_HK]: 19,
      [PayTypes.TAP_GO]: 21,
      [PayTypes.DEFERRED_BILLING]: 22,
    }

    const payMethod: number = paymentMethodRef[method];

    return payMethod;
  }

  handleShare = () => {
    const { showBottomBar } = this.state;
    this.setState({ showBottomBar: !showBottomBar })
  }

  handleOrderHistory = () => {
    const { platformHost, isLocalhost } = getDomain(this.state.realDomain);
    const protocol = `http${isLocalhost ? '' : 's'}:`;
    const { store } = this.state;
    if (store) {
      window.location.href = `${protocol}//${platformHost}${createRouteUrl(
        paths.PURCHASE_ORDER,
        {
          storeSlug: store.slug,
        },
      )}`
    } else {
      this.handleBackHome();
    }
  }


  render() {
    const { reducersState } = this.props;
    const {platformConfig, platformAppSettings } = reducersState.app;
    const { orderInfo, showBottomBar, shareData, store } = this.state;
    const { tabsOptions } = platformConfig;
    const saleTransactions = this.getSaleTransactions();
    const suppliers = platformAppSettings && platformAppSettings.suppliers ? true : false

    return (
      <Layout
        {...this.props}
        className={prefix}
        navContent={getIntl().page.payResultsTitle}
        isFooter={false}
        navIcon={suppliers && <Icon className="html-embed-7 w-embed" type={IconType.LEFT_OUTLINED} />}
        onLeftClick={suppliers ? this.handleBackHome : undefined}
      >
        <PayResultsPageStyled>
          <div className="section">
            <div className="container w-container">
              <div className="div-block-543">
                <div className="payment-status-icon _1">
                  <Icon type={IconType.STATUS_SUCCESS} className="image-202 smaller" />
                  <h1 className="heading_1">{getIntl().page.paymentSuccessful}</h1>
                  {orderInfo && [ShippingMethod.PICK_UP].includes(orderInfo.shippingMethod) && <div className="order_tips">{getIntl().page.keepOrderInfoTips}</div>}
                </div>
                <div className="wrapper-4">
                  <PayInfo
                    title={orderInfo && orderInfo.storeTitle}
                    orderInfo={orderInfo}
                    saleTransactions={saleTransactions}
                    showBarTotal={true}
                    referenceID={orderInfo && orderInfo.referenceID}
                    payResultsPage={true}
                    showBalance={true}
                    suppliers={suppliers}
                  />
                  <div className="div-block-554">
                    {
                      !(this.routeParams.fromPage && this.routeParams.fromPage === 'payBill') &&
                      <>
                        {
                          suppliers &&
                          <Button type="ghost" className="secondary-payment-confirmation-buttons-copy blue with-space w-inline-block"  onClick={this.handleOrderHistory}>
                            <div className="text-block-47 secondary">{getIntl().page.viewPurchaseOrder}</div>
                          </Button>
                        }
                        {
                          !suppliers &&
                          <Button type="ghost" className="secondary-payment-confirmation-buttons-copy blue with-space w-inline-block"  onClick={this.handleBackHome}>
                            <div className="text-block-47 secondary">{getIntl().page.backHome}</div>
                          </Button>
                        }
                        {
                          orderInfo && !orderInfo.superOrder && !suppliers &&
                          <>
                            {
                              tabsOptions.includes(TabsOption.ORDER) &&
                              <Button type="primary" disabled={orderInfo ? false : true} className="submit-button-modal close-modal w-inline-block" onClick={() => this.handleOrders()}>
                                <div className="text-block-47">{getIntl().page.myRecords}</div>
                              </Button>
                            }
                            {
                              !tabsOptions.includes(TabsOption.ORDER) &&
                              <Button type="primary" disabled={orderInfo ? false : true} className="submit-button-modal close-modal w-inline-block" onClick={() => this.handleOrders()}>
                                <div className="text-block-47">{getIntl().page.viewOrders}</div>
                              </Button>
                            }
                          </>
                        }
                        {
                          suppliers &&
                          <Button type="primary" className="submit-button-modal close-modal w-inline-block text-block-9" onClick={() => this.handleShare()}>
                            <Icon type={IconType.SHARE_OUTLINED} className="share" />
                            <div className="text-block-47">{getIntl().page.share}</div>
                          </Button>
                        }
                      </>
                    }
                    {
                      showBottomBar &&
                      <BottomBar
                        showBottomBar={showBottomBar}
                        onClick={this.handleShare}
                        data={shareData}
                        reducersState={reducersState}
                        selectStore={store}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PayResultsPageStyled>
      </Layout>
    );
  }
}

export default PayResultsPage;
