export default {
  backHome: 'Back Home',
  orderDetail: 'Order Details',
  paymentSuccessful: 'Order Succeeded!',
  paymentFailed: 'Payment Failed!',
  orderCompleted: 'Order Completed',
  payResultsTitle: 'Pay',
  selectPayTitle: 'Select Payment',
  processing: 'Processing...',
  urlErrorTip: 'Parameter exception',
  checkoutTitle: 'Checkout',
  ordersTitle: 'Orders',
  storesTitle: 'Stores',
  categoryTitle: 'Category',
  menusTitle: 'Menus',
  homeTitle: 'Home',
  recordsTitle: 'Records',
  promotionsTitle: 'Promotions',
  eshopTitle: 'eShop',
  membersTitle: 'Member\'s Area',
  creditCardTitle: 'Credit Card',
  paymentCardTitle: 'Payment Card',
  confirmAndPay: 'Confirm and Pay',
  takeAway: 'Take Away',
  delivery: 'Delivery',
  dineIn: 'Dine In',
  pickUp: 'Pick Up',
  pickUpTime: 'Pick Up Time',
  chooseLocation: 'Choose locations',
  pickupAddress: 'Pick Up Address',
  deliveryAddress: 'Delivery Address',
  book: 'Book',
  browse: 'Browse',
  foodOrdering: 'Food Ordering',
  quickTag: 'Quick {quick}',
  advanceOrderTips: '{day} day pre-order required',
  deliveryTag: 'City',
  percentageVoucherTag: 'Staff {number}% off',
  numberVoucherTag: 'Tasting Voucher {number}',
  immediately: 'Immediately',
  addPayMethod: 'Add a payment method',
  cvvOrPin: 'CVV / PIN',
  cvv: 'CVV',
  enterCvv: 'Enter CVV',
  pin: 'PIN',
  expDate: 'Exp. Date',
  cardNumber: 'Card Number',
  payCardTypeError: 'This payment card type is not currently supported',
  payCardError: 'Please enter the correct card information',
  itemsCount: '{count} Items',
  balanceNode: '(Balance {balance})',
  paying: 'Payment in progress...',
  payment: 'Payment',
  selectPaymentMethod: 'Select Payment Method',
  giftCardAlreadyBound: 'The gift card is for cardholder\'s use only',
  confirmBindCreditCard: 'I want to use this credit card for my future orders.',
  referenceID: 'Reference ID',
  placeholderCardNumber: 'Enter the card number',
  shoppingCart: 'Shopping Cart',
  ok: 'OK',
  signInPhoneNumberPlacehodler: 'Enter your phone number',
  areaCodeTitle: 'Area Code',
  signin: 'Sign In',
  history: 'History',
  results: 'Results',
  exploreByLocation: 'Explore by locations',
  location: 'Locations',
  allLocation: 'All Locations',
  chooseCheckoutMethod: 'Choose a checkout method',
  notSignInTips: 'It seems that you are not signed in yet',
  signInPleaseTips: 'To enjoy the best experience, sign in now.',
  noThanks: 'No, thanks',
  guestCheckout: 'Guest Checkout',
  mobileSignIn: 'Mobile Sign In',
  continueAsGuest: 'Continue As Guest',
  suspend: 'Suspend',
  deactivate: 'Inactive',
  soldOut: 'Sold Out',
  option: 'Options',
  choose: 'Choose {quanity} ',
  chooseUpTo: 'Choose up to {quanity} ',
  chooseAtLeast: 'Choose at least {quanity} ',
  chooseRange: 'Choose at least {quanityAtLeast} , Choose up to {quanityUpTo} ',
  itemInformation: 'Item Information',
  productName: 'Product Name',
  productPrice: 'Product Pricing',
  productRemark: 'Product Remark(Optional)',
  custom: '(Custom)',
  enter: 'Enter',
  noAccount: 'Don\'t have an account?',
  signUpNow: 'Sign up now',
  mobileSignin: 'Mobile Sign In',
  emailSignin: 'Email Sign In',
  signInEmailPlacehodler: 'Enter you email',
  forgot: 'Forgot',
  forgotPassword: 'Recover Password',
  mobileRetrieve: 'Mobile Retrieve',
  emailRetrieve: 'Email Retrieve',
  emailRetrieveText: 'we will send you an email with instructions to recover your password',
  verifyCode: 'Verification code',
  favorite: 'Favorite',
  address: 'Address',
  setting: 'Settings',
  clearCache: 'Clear Cache',
  changePassword: 'Change Password',
  language: 'Language',
  terms: 'Terms and Conditions',
  privacy: 'Privacy Policy',
  closed: 'Closed',
  opening: 'Open',
  openHours: 'Operation Hours',
  qrCodeTips: 'Tips',
  qrCodeTipsTitle: 'Merchant Profile Not Activated Yet',
  qrCodeTipsCon: 'Please fill in the required applications to start the activation process. For any inquiry, please contact Bindo through onboarding_hk@bindo.com',
  fbProgramApplication: 'F&B Program Application',
  paymentApplication: 'All In One Payment Application',
  featured: 'Featured',
  allStores: 'All Stores',
  allMenus: 'All Menus',
  viewAll: 'View All',
  orderTime: 'Order Time',
  allOrders: 'All Orders',
  current: 'Current',
  completed: 'Completed',
  accountTitle: 'Account',
  save: 'Save',
  lineItemClosed: 'Closed',
  diningStyleTitle: 'How would you like to enjoy your food?',
  gender: 'Gender',
  chooseGender: 'Choose Gender',
  male: 'Male',
  female: 'Female',
  other: 'Not disclosed',
  signup: 'Sign Up',
  usernamePlaceholder: 'Enter your name',
  signUpmobile: 'Mobile Sign Up',
  signUpemail: 'Email Sign Up',
  chooseLanguage: 'Choose Language',
  noOrders: 'No Order History.',
  noOrdersTip: 'You don’t have order history yet.',
  pleaseSignIn: 'Please sign in to get started.',
  reOrder: 'Re-order',
  invoice: 'Fapiao',
  username: 'Name',
  email: 'Email',
  phone: 'Phone',
  birthday: 'Date of Birth',
  paymentMethod: 'Payment Method',
  noPlatformDescribe: 'The page does not exist or the path is wrong',
  iAgree: 'I agree',
  splashDone: 'Get Started',
  haveAccount: 'Don\'t have an account?',
  privacyPolicy: 'Privacy Policy',
  termsandConditions: 'Terms and Conditions',
  signOut: 'Sign Out',
  orderNumber: 'Order Number',
  takeAwayTime: 'Take away time',
  shoppingCartEmpty: 'The shopping cart is empty',
  startOrdering: 'Start Ordering',
  favoriteListEmpty: 'No Data',
  errorPhoneTips: 'please enter a valid phone number',
  signInTips: 'Please sign in',
  preparingTip: 'Your order is being prepared now. Stay tuned.',
  readyTip: 'Your order is ready for pick up!',
  completedTip: 'How was your food? Leave us a feedback!',
  paidTip: 'Business has received orders, please be patient.',
  unpaidTip: 'The order will be cancelled.',
  emailReceipt: 'Email Receipt',
  userNoEmail: 'Please verify your email address on the Account page first.',
  guestTip: 'To continue, please enter email address.',
  enterEmail: 'Enter your email address',
  emailError: 'Incorrect email address format',
  sendEmail: 'send...',
  feedback: 'Feedback',
  sentSuccess: 'Sent Successfully',
  emailAddress: 'The email receipt has been sent to your email address',
  feedBackTitle: 'How was {storeTitle}?',
  feedBackTip: 'We’ll share your rating with the restaurant.',
  procurementMethod: 'Procurement Method',
  confirmTableNumber: 'Confirm Table Number',
  enterTableNumber: 'Enter table number',
  tableNumberNotExist: 'Table number does not exist',
  chooseTime: 'Choose Time',
  confirmTableNumberTips: 'Scan QR code or enter manually',
  delete: 'Delete',
  deleteTips: 'Remove this Credit Card?',
  booking: 'Booking',
  bookings: 'Bookings',
  queuedSuccess: 'Queued Successfully',
  callingNumber: 'Current Calling Number',
  coverNo: 'Number of People',
  enterPeople: 'Enter the number of people',
  queueingTip: 'If 3 or more calling numbers have passed your registered ticket number will be invalid. Please register again.',
  yourNumber: 'Your Queue Number',
  queueTimes: 'Queue Times',
  seats: 'Seats',
  home: 'Home',
  expired: 'Expired',
  queueNumber: 'Queue Number',
  queueing: 'Queuing',
  facebookSignin: 'Sign in with Facebook',
  adult: 'Adult',
  child: 'Child',
  chooseDate: 'Choose date',
  contactDetail: 'Contact detail',
  note: 'Note',
  notePlaceholder: 'Add a note (extra, no pepper, no onion, etc.)',
  namePlaceholder: 'Enter your name',
  date: 'Date',
  time: 'Time',
  phoneNumber: 'Phone Number',
  name: 'Name',
  bookingNo: 'Booking No.',
  goBack: 'Go Back',
  bookingID: 'booking id:',
  bookingSuccess: 'Booked Successfully',
  bookingSuccessful: 'Received your reservation successfully',
  bookingFailure: 'Booking Cancelled',
  booked: 'Pending',
  arrived: 'Arrived',
  cancelled : 'Cancelled ',
  confirmed: 'Confirmed',
  noShow: 'NoShow',
  voicemail: 'Voicemail',
  partiallyArrived: 'PartiallyArrived',
  late: 'Late',
  waitingForCon: 'Waiting',
  failure: 'Failure',
  bookingSuccessfulTips: 'Our staff will get in touch soon to confirm your booking',
  sameCardTip: 'Do not add the same card',
  signInErrorMsg: 'Account Number or Password Invalid. Please enter again.',
  menuFunc: 'Menus',
  feedbackFunc: 'Feedback',
  newsFunc: 'News',
  orderFunc: 'Order',
  aboutUsFunc: 'About Us',
  tableNumber: 'Table {tableNumber}',
  cancelBooking: 'Cancel Booking',
  backToHome: 'Back to Homepage',
  createAt: 'Created At',
  bookingFull: 'The reservation period is full. Please change the reservation time',
  cancelBookingTip: 'Are you sure you want to cancel this booking?',
  termsAndConditionPre: 'By completing this order, I agree to the',
  termsAndCondition: 'Terms & Conditions',
  voidedTip: 'Looking forward to seeing you again.',
  wechatSignin: 'Sign in with Wechat',
  appleSignin: 'Sign in with Apple',
  averageFeedback: 'Average Feedback',
  icons: 'Icons',
  deviceInfo: 'Device Info',
  newsTitle: 'News',
  aboutUsTitle: 'About Us',
  noMoreContent: 'No more content',
  createStore: 'Create Store',
  storeInfo: 'Store Info',
  stores: 'Bindo Stores',
  fullName: 'Full Name',
  storeName: 'Store Name',
  country: 'Country or region',
  storeAddress: 'Store Address',
  zipCode: 'Zip Code',
  shopType: 'Shop Type',
  storeImages: 'Store Images',
  createTip: 'Almost done! {time} mins left!',
  retail: 'Retail',
  dinner: 'Restaurant',
  fastFood: 'Fast Food',
  inactive: 'Inactive',
  noBookingTip: 'No Booking',
  noQueueingTip: 'No Queuing',
  feedbackTip: 'No Feedback',
  useNameInvalid: 'Invalid username',
  phoneInvalid: 'Invalid phone number',
  emailInvalid: 'Invalid email',
  storeAssociad: 'Store Associations',
  valid: 'Valid',
  invalid: 'Invalid',
  approval: 'Approval',
  platformName: 'Platform Name',
  status: 'Status',
  environment: 'Environment',
  storeID: 'Store ID',
  clearCacheSuccess: 'Cache cleared successfully',
  createUserError: 'This email is already registered',
  createStoreFailed: 'Failed to create store, please check the input',
  bindo: 'Bindo',
  points: 'Points',
  point: 'Point',
  viewDetail: 'View Detail',
  upcomingBooking: 'Upcoming Reservations',
  newBooking: 'New Booking',
  previousBooking: 'Previous Reservations',
  noBooking: 'No upcoming reservations. Feeling hungry?',
  dateOfBirth: 'Date of Birth: {date}',
  loginIn: 'Log In or Register',
  becomeMemberTip: 'Become a member to enjoy exclusive privileges and rewards',
  noQueue: 'There is no queuing in this store',
  noListings: 'There are no items in the selected dining mode',
  invalidLineItemTips: 'Inventory, Meal style, time and other causes of failure of the sale of goods',
  myIDTitle: 'My ID',
  scanYourQRCode: 'Scan Your QR Code to earn more Points',
  knowMore: 'Want to know more about',
  dollars: 'Dollars?',
  clickHere: 'Click Here',
  aptSuite: 'Apt/suite',
  storeConfigures: 'Store Configures',
  information: 'Information',
  selectProcedureMethod: 'Select procedure method',
  platformID: 'Platform ID',
  takeAwaySetup: 'Take Away Setup',
  deliveryAdvanceOrderDays: 'Delivery Advance Order Days',
  PickUpInterval: 'Pick Up Interval',
  deliverySetup: 'Delivery Setup',
  deliveryMiles: 'Delivery Miles',
  deliveryFee: 'Delivery Fee',
  deliveryDesc: 'Delivery Desc',
  dineInUnassigned: 'Dine In Unassigned',
  deliveryMinAmount: 'Delivery Min Amount',
  addAddress: 'Add address',
  deleteAddressTips: 'Remove this address?',
  placeholderApt: 'Enter the Apt/suite',
  placeholderReceiver: 'Enter the Receiver',
  placeholderPhone: 'Enter the phone number',
  receiver: 'Receiver',
  currentAmount: 'Current Points Balance',
  spend: 'Spend Now',
  myPrivilege: 'My Privileges',
  myCoupons: 'My Coupons',
  used: 'Used / Expired',
  dollarsTitle: 'Dollars',
  brands: 'Brands',
  seeAll: 'See all',
  people: 'people',
  latestUpdate: 'latest update at: ',
  redemptionPeriod: 'Coupon Redemption Date: ',
  couponExpiryDate: 'Coupon Expiry Date: ',
  useNow: 'Use Now',
  couponNote: 'Product Details',
  couponUsed: 'Used',
  couponExpired: 'Expired',
  url: ' URL',
  otherOrders: 'Other orders',
  share: 'Share',
  route: 'Route',
  applemap: 'Apple Map',
  gaodemap: 'Gaode Map',
  googlemap: 'Google Map',
  noOpenBooking: 'There is no booking in this store',
  earnPoints: 'Earn points',
  redemption: 'Redemption',
  noPoint: 'Sorry! no data found',
  noDataTips: 'Sorry! no data found',
  usePoints: 'Use Points',
  aboutPoints: 'About Points',
  membershipGuide: 'Membership Guide',
  payPoint: 'Redeem With Points',
  tggMembers: '{abbreviation} Membership',
  myAccount: 'My Account',
  tggDollars: '{abbreviation} Dollars',
  tableBooking: 'Table Booking',
  dine: 'Dine',
  shopping: 'Shopping',
  aboutTGG: 'About {abbreviation}',
  availablePoints: 'Available Points',
  rewardStatus: 'Reward Status',
  pointsEqDollar: '1 Point = 1 {dollar}',
  dollarEqPoints: '1 {dollar} = HK$1',
  minimumSpending: 'The minimum spending is 20 {dollar}',
  Reward: 'Reward',
  enterPoint: 'Select the number of points you want to redeem',
  pointDetail: '*There’s no service charge required for any Takeaway order\n*Points are accumulated as per tier\n*The TGG Points and Dollars can only be used for E-payments',
  map: 'Map',
  all: 'All',
  filters: 'Filters',
  cuisine: 'Cuisine',
  distance: 'Distance',
  price: 'Price',
  seatedTime: 'Seated Time',
  seatedTableNumber: 'Table No.',
  headcount: 'Headcount',
  referenceNumber: 'Reference No.',
  qrcodeShoppingResultTitle: 'Order',
  submitSuccess: 'Order Submitted!',
  newItems: 'New Items',
  pendingItems: 'Pending',
  submittedItems: 'Submitted',
  orderNow: 'Order Now',
  addShoppingCart: 'Add to Cart',
  qrcodeInvalid: 'QR code is invalid. Please contact the restaurant staffs if you would like to make orders',
  recentAcitivities: 'Recent Acitivities',
  nextTier: 'Amount to next tier',
  moneyPoint: 'Every {money} spent = Earn {point} point',
  allBrands: 'All Brands',
  comingSoon: 'Coming Soon...',
  payBill: 'Pay Bill',
  description: 'Description',
  myBookings: 'My Booking',
  myQueueings: 'My Queuing',
  myRecords: 'My Records',
  myFavorites: 'My Favorite',
  myAddress: 'My Address',
  myPayments: 'My Payment',
  viewShoppingCart: 'View Cart',
  redeemSucceeded: 'Redeem Succeeded',
  pointBalance: 'Point Balance',
  storeCredit: 'Store Credit',
  pointTip: 'You’ve redeemed {integral} point to your balance',
  redeemTip: 'Spend {abbreviation} Points to explore exclusive products and privileges',
  birthdayTip: 'Share with us your birth date to enjoy birthday surprises!',
  supplierOrderfor: 'Who are you ordering for?',
  buyerUserID: 'Your User ID is ',
  buyerUserTips: 'It looks like you are not associated to any stores yet. Please contact or wait for the ',
  buyerUserContact: ' team to follow up with this!',
  register: 'Register',
  orderSynchronized: 'Orders are being Synchronized',
  tryAgain: 'Please try again later',
  retry: 'Retry',
  selectDate: 'Select Birthday',
  accumulatedSpendings: 'Accumulated Spendings = {amount}',
  spendMoney: 'Spend',
  upgradedTo: 'to be upgraded to',
  highestLevelTips: 'You are currently upgraded to the highest level!',
  registeredAndSpent: 'Registered and spent',
  spentYear: 'Spent a year',
  registerAsMember: 'Register As A Member',
  loginUser: 'User Login',
  scanner: 'Scanner',
  earnPointsFail: 'The QR code is invalid or expired',
  earnPointsSuccess: 'Successful!',
  membershipExpire: 'Expiring on {date}',
  district: 'District',
  members: 'Members',
  everySpending: 'Every Spending',
  asap: 'Now',
  canSchedule: 'Schedule for later',
  notSchedule: 'Cant’t schedule for later',
  differentShippingMethodTips: 'You have already selected a different procurement method. If you continue your cart and selection will be removed.',
  selectPageextra: 'Have a membership card, but not an account?',
  placeClickHere: 'Please click here',
  receiveInformation: 'Yes! I would like to receive special offers，promotion and other information.',
  itemsTipsTitle: 'Are you old enough to buy age restricted items',
  itemsTipsTitleContent: 'Age restricted items (including alcohol and tobacco) are not for sale to anyone under the age of 18. You may need to provide a valid ID upon receiving your order.',
  confirmOverAge: 'YES, IM OVER 18',
  currentLevelTitile: 'Your Current Level',
  myProfile: 'My Profile',
  depositeRequired: 'Deposite Required',
  dollarsBalance: 'Dollars Balance',
  shareVia: 'Share this order via',
  copy: 'Copy',
  copySuccessMsg: 'Copy success',
  shareSuccessMsg: 'Share success',
  recentOrders: 'Recent Orders',
  seeAllSuppliers: 'See all suppliers',
  searchByNames: 'Search By Names...',
  showStoreTotal: 'Show {total} results',
  deliverySchedule: 'Delivery Schedule',
  freeDelivery: 'Free Delivery',
  freeDeliveryAbove: 'Free Delivery Above {amount}',
  remove: 'Remove',
  shippingCarNotePlaceholder: 'Please state any additional requests/ remarks',
  remark: 'Remark',
  expressOrderTitle: 'Express Order',
  searchItems: 'Search for items...',
  inventoryOptions: 'Inventory Options',
  addToExpressOrder: 'Add to Express Order',
  defaultRecorder: 'Default Reorder Amount',
  addItem: '+ Add Item',
  showQRTip: 'Please show the QR Code upon collecting the order',
  enterVerifyCode: 'Please enter a valid verification code',
  resendCode: 'Resend Verification Code',
  invalidToken: 'Please sign in again',
  signCancel: 'Cancel',
  logInAgain: 'Confirm',
  tips: 'Tips',
  registerDobMsg: 'Please provide true date of birth to receive birthday surprise!',
  resendCodeTimer: 'Resend Verification Code ({time}s)',
  reminder: 'Reminder',
  agree: 'AGREE',
  viewOrders: 'View Orders',
  newPassword: 'Insert new password',
  passwordLength: 'For your account security,  please use 6 characters or more for your password.',
  enterPayment: 'Enter payment amount',
  checkOut: 'Pay Bill',
  paymentNotOpened: 'The store has not turned on the pay bill function',
  mobileRepeat: 'This mobile phone number has been registered to an account, please try to login or reset password if you forgot your password',
  sendCode: 'Send Verification Code',
  storeNoBusinessTime: 'The shop is closed',
  noPointsAvailable: 'No points available for redemption',
  expiryDate: 'Expiry Date',
  alreadySoldOut: 'is sold out, please choose other products',
  paymentConfirmation: 'Payment Confirmation',
  pointTipCustom: 'You’ve redeemed {loyalty} {abbreviation} Dollar from your point balance',
  changePasswordSuccessfully: 'Update success! Please use the new password to login.',
  passwordToShort: 'For your account security, The password must not be short than 6, Please use a longer one',
  invalidAgain: 'Invalid authorize code or phone, please try again',
  unknownStore: 'The unknown store provided!',
  registered: 'This phone number has been registering, you can try to login or reset your password if you forget your password',
  invalidCode: 'Invalid auth code, please check',
  encryptionPassword: 'Encrypt user password failed with error',
  loginAlreadyInUse: 'The login is not available, please use another one',
  invalidEmailFormat: 'Email:{failed} format is invalid',
  createUserFailed: 'Create user failed with error',
  serviceUnavailable: 'Service temporarily unavailable, please try later',
  failedToCreateLogin: 'Create user failed at update user login with error',
  saveCreateUserFailed: 'Create user failed at save phone with error',
  saveCreateLinkedFailed: 'Create user failed at linked customer，create customer failed with error',
  emailIsUser: 'Email:{failed} is already used',
  createEmailFailed: 'Create email for user failed with error:{failed}',
  updateVerifyCodeFailed: 'Update verify code status failed with error:{failed}',
  createPresenterFailed: 'Create user presenter failed',
  loginFailed: 'Unable to obtain user information. Please log in again.',
  currentPtsCustom: '{abbreviation} Points Balance',
  pointsCustom: '{abbreviation} Points',
  payPointCustom: 'Redeem With {abbreviation} Points',
  earnPointsCustom: 'Earn {abbreviation} points',
  available: 'Available',
  unavailable: 'Unavailable',
  purchaseOrderTitle: 'Purchase Order History',
  viewPurchaseOrder: 'Order History',
  goodReceivedNotesTitle: 'Good Received Notes',
  noPurchasrOrderTip: 'No Purchase Order',
  purchaseOrder : 'Purchase Order',
  alert: 'Alert',
  purchaseOrderCancelTip: 'Are you sure want to cancel this Purchase Order?',
  accountManager: 'Account Manager',
  expectedDeliveryDate: 'Expected Delivery Date',
  itemQuantity: 'Item Quantity',
  serviceFee: 'Service Fee',
  qty: 'Qty',
  code: 'Code',
  receive: 'Receive',
  remarkPlaceholder: 'Add Remarks (Please ship on time, etc.)',
  supplierName: 'Supplier Name',
  receivedOn: 'Received On',
  deliveryReceived: 'Delivery Received',
  receivedBy: 'Received By',
  poCancelled: 'Cancelled',
  received: 'Received',
  created: 'Created',
  accept: 'Accepted',
  orderQuantity: 'Order Quantity',
  goodReceivedNoteCancelTip: 'Are you sure want to cancel this Goods Received Note?',
  goodReceivedNoteReceiveTip: 'Are you sure want to receive this Goods Received Note?',
  purchaseOrderDetail: 'Purchase Order Details',
  create: 'Create',
  createReceive: 'Create & Receive',
  deliveredQuantity: 'Delivered Quantity',
  fulfillAll: 'Fulfill All',
  newGoodsReceivedNote: 'New Goods Received Note',
  dDollars: 'Dollars',
  outOfStock: 'This item is out of stock',
  enterPromotionCode: 'Please enter a valid discount code',
  readAgree: 'I\'ve read and understood',
  nowItemsCounts: '{count} Item',
  clearShoppingCartTip: 'Please confirm if you would like to remove all the items in the cart',
  leaveTheShoppingCartTip: 'You have an incomplete order from this store. Do you want to leave this page? \n *Your item(s) will be saved in cart.',
  moreThanOneStoresTip: 'You added items from more than 1 store. Are you sure to proceed to checkout?',
  stay: 'Stay',
  leave: 'Leave',
  editCart: 'Edit Cart',
  usePointTip: 'Once you have redeemed {name} Dollars with Points, you cannot refund',
  usePointRedeem: 'Redeem',
  showRedeemable: 'Show Redeemable',
  redeemWidth: 'Redeem With:',
  redeemNow: 'Redeem Now',
  confirmRedeemTips: 'Once {name} have been used, they cannot be refunded.',
  return: 'Return',
  newReturnNote: 'New Return Note',
  returnNotesTitle: 'Return Notes',
  returned: 'Returned',
  returnNoteCancelTip: 'Are you sure want to cancel this Return Note?',
  returnNoteReceiveTip: 'Are you sure want to return this Return Note?',
  receiveTip: 'You cannot “Receive” this order as it has not been “Accepted”.',
  deferredInvoices: 'Deferred Invoices',
  thisWeek: 'This week',
  thisMonth: 'This month',
  monthAgo: 'More than 1 month ago',
  invoices: 'Invoices',
  lastInvoiceDate: 'Last Invoice Date',
  payAll: 'Pay All',
  viewDetails: 'View Details',
  othersProducts: 'Others Products',
  payNow: 'Pay Now',
  backDataTips: 'Please be aware that items cannot be modified upon proceeding',
  openInWeChatTips: 'WeChat scanning for ordering is not supported. Please use a mobile scanner to scan the QR code',
  keepOrderInfoTips: 'Please keep this order number page for order pick up. Thank you!',
}
