export default {
  common: {
    total: '合计',
    subtotal: '小计',
    paidTotal: '已付金额',
    fullPaid: '全部支付',
    invoiceState: '支付类型',
    discount: '折扣',
    serviceFee: '服务费',
    taxFee: '税额',
    rounding: '除整',
    payment: '支付',
    today: '今天',
    tomorrow: '明天',
    now: '现在',
    save: '保存',
    tips: '提示',
    confirm: '确认',
    loading: '加载中...',
    payfail: '支付失败, 请稍后再试',
    search: '搜索',
    password: '密码',
    cancel: '取消',
    change: '更换',
    back: '返回',
    continue: '继续',
    send: '发送',
    monday: '星期一',
    tuesday: '星期二',
    wednesday: '星期三',
    thursday: '星期四',
    friday: '星期五',
    saturday: '星期六',
    sunday: '星期日',
    unpaid: '未支付',
    paid: '已支付',
    preparing: '准备中',
    ready: '已准备好',
    completed: '已完成',
    next: '下一步',
    voided: '取消',
    no: '否',
    yes: '是',
    Mon: '一',
    Tue: '二',
    Wed: '三',
    Thu: '四',
    Fri: '五',
    Sat: '六',
    Sun: '日',
    Jan: '一月',
    Feb: '二月',
    Mar: '三月',
    Apr: '四月',
    May: '五月',
    Jun: '六月',
    Jul: '七月',
    Aug: '八月',
    Sep: '九月',
    Oct: '十月',
    Nov: '十一月',
    Dec: '十二月',
    submit: '提交',
    edit: '编辑',
    payErrorTips: '支付:交易金额不能低于0.2',
    activated: '激活',
    miniProgramTips: '“添加到我的小程序” 使用更快捷',
    searchHere: '搜索附近',
    any: '任意',
    type: '类型',
    paymentAmount: '支付金额',
    month: '月',
    day: '日',
    order: '订单',
    deliverOn: '交付时间为',
    shallOrderBy: '{customer}{phone}通过 有鸟到 在{deliveryDate}下的订单',
    birdymart: '有鸟到',
    customer: '顾客：',
    sendTo: '收件人：',
    subject: '主题：',
    subjectCon: '有鸟到 订单 - 订单编号: {referenceID}',
    subjectTo: '有鸟到 订单',
    emailSent: '邮件已发送！',
    monthCapitals: '月',
    dayCapitals: '日',
    yearCapitals: '年',
    partialPaid: '部分支付',
    fulfilled: '完成',
    partiallyFulfilled: '部分完成',
    deferred: '延迟',
    errorCode: '错误代码',
    errorMsg: '错误消息',
  },
  invoice: {
    invoice: '发票',
    enterprise: '企业',
    personal: '个人',
    invoiceName: '姓名',
    invoiceEmail: '邮箱',
    invoiceType: '发票类型',
    otherInfo: '其他信息',
    companyName: '企业名称',
    taxID: '税务编号',
    beneficiaryBank: '收款行',
    bankAccount :'银行账户',
    enterpriseAddress: '企业地址',
    enterprisePhone: '企业电话',
    confirm: '确认',
    submitted1: '发票已提交',
    submitted2: '请稍后检查电话/邮箱或扫描',
    submitted3: '发票二维码再次下载发票',
    invoicing1: '正在开发票',
    invoicing2: '已成功提交发票信息',
    invoicing3: '正在开发票。请稍候。。。',
    failed1: '未能提交申请',
    failed2: '您的发票申请失败，请重新开具发票',
    invoiceQR1: '二维码无效',
    invoiceQR2: '请联系商家：',
    invoiceWait1: '订单正在同步',
    invoiceWait2: '发票正在同步，请',
    invoiceWait3: '稍后再开发票',
    payer: '付款人',
    payee: '收款方',
    invoiceAmount: '发票金额',
    issueTime: '开票时间',
    invoiceCode: '发票代码',
    invoiceNumber: '发票号码',
    downloadInvoice: '下载发票（PDF）',
    rebill: '重开发票',
    viewDetails: '详情',
  },
  page: {
    backHome: '返回首页',
    orderDetail: '订单详情',
    paymentSuccessful: '下单成功!',
    paymentFailed: '支付失败!',
    orderCompleted: '订单已完成',
    payResultsTitle: '支付',
    selectPayTitle: '收银台',
    processing: '处理中...',
    urlErrorTip: '参数异常',
    checkoutTitle: '下单',
    ordersTitle: '订单',
    storesTitle: '店铺',
    categoryTitle: '分类',
    menusTitle: '菜单',
    homeTitle: '首页',
    recordsTitle: '记录',
    promotionsTitle: '推广',
    eshopTitle: '购物',
    membersTitle: '会员',
    paymentCardTitle: '支付卡',
    creditCardTitle: '信用卡',
    confirmAndPay: '确认并支付',
    takeAway: '外卖',
    delivery: '速递',
    dineIn: '堂食',
    pickUp: '自取',
    pickUpTime: '自取时间',
    chooseLocation: '选择地址',
    pickupAddress: '提货地址',
    deliveryAddress: '速递地址',
    book: '餐桌预定',
    browse: '全部',
    foodOrdering: '点餐',
    quickTag: '运费 {quick}',
    advanceOrderTips: '需要提前{day}天订购',
    deliveryTag: '城市',
    percentageVoucherTag: 'Staff {number}% off',
    numberVoucherTag: 'Tasting Voucher {number}',
    immediately: '立即',
    addPayMethod: '添加支付方式',
    cvvOrPin: 'CVV / PIN',
    cvv: 'CVV',
    enterCvv: '输入CVV',
    pin: 'PIN',
    expDate: '过期日',
    cardNumber: '卡号',
    payCardTypeError: '目前不支持这类型的支付卡',
    payCardError: '请输入正确的卡信息',
    itemsCount: '{count} 商品',
    balanceNode: '(余额 {balance})',
    paying: '正在支付...',
    payment: '支付',
    selectPaymentMethod: '选择支付方式',
    giftCardAlreadyBound: '该礼品卡仅限持卡人使用',
    confirmBindCreditCard: '我想将此信用卡用于我未来的订单',
    referenceID: '查阅编号',
    placeholderCardNumber: '输入卡号',
    shoppingCart: '购物车',
    ok: '确定',
    signInPhoneNumberPlacehodler: '请输入电话号码',
    areaCodeTitle: '地区代码',
    signin: '登录',
    history: '历史',
    results: '结果',
    exploreByLocation: 'Explore by locations',
    location: '区域',
    allLocation: '所有区域',
    chooseCheckoutMethod: '选择一种结账方式',
    notSignInTips: '您似乎尚未登录',
    signInPleaseTips: '要享受最佳体验，请立即登录',
    noThanks: '不，谢谢',
    guestCheckout: '访客结账',
    mobileSignIn: '手机号登录',
    continueAsGuest: '以访客身份继续',
    suspend: '暂停',
    deactivate: '停用',
    soldOut: '售罄',
    option: '选项',
    choose: '选择 {quanity} ',
    chooseUpTo: '选择最多 {quanity} ',
    chooseAtLeast: '至少选择 {quanity} ',
    chooseRange: '至少选择 {quanityAtLeast} , 最多选择 {quanityUpTo} ',
    itemInformation: '商品信息',
    productName: '产品名称',
    productPrice: '产品价格',
    productRemark: '产品备注（选填）',
    custom: '(自定义)',
    enter: '输入',
    noAccount: '还没有账号?',
    signUpNow: '立即注册',
    mobileSignin: '手机号登录',
    emailSignin: '邮箱登录',
    signInEmailPlacehodler: '请输入邮箱',
    forgot: '忘记密码',
    forgotPassword: '忘记密码',
    mobileRetrieve: '手机号取回',
    emailRetrieve: '邮箱取回',
    emailRetrieveText: '我们将向您发送一封电子邮件，说明如何恢复您的密码',
    verifyCode: '验证码',
    favorite: '收藏',
    address: '地址',
    setting: '设置',
    clearCache: '清除缓存',
    changePassword: '修改密码',
    language: '语言',
    terms: '使用条款',
    privacy: '隐私声明',
    closed: '暂停营业',
    opening: '营业中',
    openHours: '营业时间',
    qrCodeTips: '提示',
    qrCodeTipsTitle: '商家资料尚未激活',
    qrCodeTipsCon: '请填写应用程序启动激活所需的资料。 如有任何查询，请通过onboarding_hk@bindo.com与Bindo联系。',
    fbProgramApplication: '餐饮程序申請',
    paymentApplication: '聚合支付申請',
    featured: '特色店铺',
    allStores: '全部店铺',
    allMenus: '全部菜单',
    viewAll: '查看全部',
    orderTime: '下单时间',
    allOrders: '全部订单',
    current: '当前',
    completed: '已完成',
    accountTitle: '账户',
    save: '保存',
    lineItemClosed: '非可售时间',
    gender: '性别',
    chooseGender: '选择性别',
    male: '男',
    female: '女',
    other: '不透露',
    signup: '注册',
    usernamePlaceholder: '请输入用户名',
    signUpmobile: '手机号注册',
    signUpemail: '邮箱注册',
    chooseLanguage: '选择语言',
    noOrders: '没有订单历史记录。',
    noOrdersTip: '你还没有订单记录。',
    pleaseSignIn: '请登录以开始。',
    reOrder: '再来一单',
    invoice: '发票',
    username: '用户名',
    email: '电子邮件',
    phone: '手机号码',
    birthday: '出生日期',
    paymentMethod: '支付方式',
    noPlatformDescribe: '页面不存在或者路径错误',
    iAgree: '我同意',
    splashDone: '开始',
    haveAccount: '还没有账号?',
    privacyPolicy: '《隐私政策》',
    termsandConditions: '《使用条款》',
    signOut: '登出',
    orderNumber: '订单编号',
    takeAwayTime: '取货时间',
    shoppingCartEmpty: '空购物车',
    startOrdering: '开始订购',
    favoriteListEmpty: '暂无收藏',
    errorPhoneTips: '请输入正确的手机号',
    signInTips: '请先登录',
    preparingTip: '您的订单正在准备中。敬请期待。',
    readyTip: '您点的菜准备好了！',
    completedTip: '你的食物怎么样？给我们一个反馈！',
    paidTip: '系统已收到订单，请耐心等待。',
    unpaidTip: '订单将被取消。',
    emailReceipt: '邮件收据',
    userNoEmail: '请首先在“帐户”页面上验证您的电子邮件地址。',
    guestTip: '若要继续，请输入电子邮件地址。',
    enterEmail: '输入您的电子邮件地址',
    emailError: '邮件地址格式错误',
    sendEmail: '发送中...',
    feedback: '反馈',
    sentSuccess: '发送成功',
    emailAddress: '电子邮件收据已发送到您的电子邮件地址',
    feedBackTitle: '{storeTitle}怎么样?',
    feedBackTip: '我们会和餐厅分享你的评分。',
    procurementMethod: '就餐方式',
    confirmTableNumber: '确认餐桌编号',
    enterTableNumber: '请输入餐桌编号',
    tableNumberNotExist: '餐桌编号不存在',
    chooseTime: '选择时间',
    confirmTableNumberTips: '扫二维码或手动输入',
    delete: '删除',
    deleteTips: '删除这张信用卡？',
    bookings: '预约',
    queuedSuccess: '已成功排队',
    callingNumber: '当前主叫号码',
    coverNo: '人数',
    enterPeople: '输入人数',
    queueingTip: '如入座票号超过阁下所持的票号3个号码或以上，请重新取票。',
    yourNumber: '您的队列号',
    queueTimes: '排队时间',
    seats: '座位',
    home: '首页',
    expired: '失效',
    queueNumber: '队列编号',
    booking: '订座',
    queueing: '排队',
    facebookSignin: 'Facebook登录',
    adult: '成人',
    child: '儿童',
    chooseDate: '选择日期',
    contactDetail: '联系人详细信息',
    note: '备注',
    notePlaceholder: '添加备注（额外的，不要胡椒粉，不要洋葱等）',
    namePlaceholder: '输入姓名',
    date: '日期',
    time: '时间',
    phoneNumber: '手机号码',
    name: '姓名',
    bookingNo: '预约号',
    goBack: '返回',
    bookingID: '预约号:',
    bookingSuccess: '预约成功',
    bookingSuccessful: '您的预约申请已收到',
    bookingFailure: '预约失败',
    booked: '等待确认',
    arrived: '到达',
    cancelled: '预定取消',
    confirmed: '已确认',
    noShow: '未到达',
    voicemail: '已留言',
    partiallyArrived: '就坐',
    late: '迟到',
    waitingForCon: '等待确认',
    failure: '失败',
    bookingSuccessfulTips: '请稍后店员来电确认申请',
    sameCardTip: '请勿添加相同的卡片',
    signInErrorMsg: '请输入正确账号或密码',
    menuFunc: '菜单',
    feedbackFunc: '反馈',
    newsFunc: '新闻',
    orderFunc: '点餐',
    aboutUsFunc: '店铺资料',
    tableNumber: '桌号{tableNumber}',
    cancelBooking: '取消预约',
    backToHome: '返回首页',
    createAt: '创建时间',
    bookingFull: '该时间段预约已满，请更换预约时间',
    cancelBookingTip: '你确定要取消这个预约吗？',
    termsAndConditionPre: '完成此订单即表示我同意',
    termsAndCondition: '《使用条款》',
    voidedTip: '期待您再次光临。',
    wechatSignin: '微信登录',
    appleSignin: '苹果登录',
    averageFeedback: '平均反馈',
    icons: '图标',
    deviceInfo: '设备信息',
    newsTitle: '最新消息',
    aboutUsTitle: '关于我们',
    noMoreContent: '没有更多内容',
    createStore: '创建店铺',
    storeInfo: '店铺信息',
    stores: '边度店铺',
    fullName: '用户名',
    storeName: '店铺名称',
    country: '国家或地区',
    storeAddress: '店铺地址',
    zipCode: '邮政编码',
    shopType: '店铺类型',
    storeImages: '店铺图片',
    createTip: '快完成了！还剩{time}分钟！',
    retail: '零售',
    dinner: '正餐',
    fastFood: '快餐',
    inactive: '未激活',
    noBookingTip: '没有预约',
    noQueueingTip: '没有排队',
    feedbackTip: '暂无反馈',
    useNameInvalid: '无效的用户名',
    phoneInvalid: '无效的手机号',
    emailInvalid: '无效的邮箱',
    storeAssociad: '平台入驻',
    valid: '有效的',
    invalid: '注销',
    approval: '批准',
    platformName: '平台名称',
    status: '状态',
    environment: '环境',
    storeID: '店铺ID',
    clearCacheSuccess: '清除缓存成功',
    createUserError: '此邮箱已被注册',
    createStoreFailed: '创建店铺失败，请检查输入',
    bindo: '边度',
    points: '积分',
    point: '积分',
    viewDetail: '查看详情',
    upcomingBooking: '即将到来的预订',
    newBooking: '新预订',
    previousBooking: '过往预订',
    noBooking: '渴望美食吗？立即订座',
    dateOfBirth: '生日: {date}',
    loginIn: '登录或注册',
    becomeMemberTip: '成为会员，享受专属特权和奖励',
    noQueue: '这个店铺没有开通排队',
    noListings: '所选就餐方式没有可选菜品',
    invalidLineItemTips: '库存、餐式、时间等原因导致商品销售失败',
    myIDTitle: '我的ID',
    scanYourQRCode: '扫描您的二维码以赚取更多积分',
    knowMore: '想更多地了解',
    dollars: 'Dollars?',
    clickHere: '点击这里',
    aptSuite: '详细地址',
    storeConfigures: '店铺配置',
    information: '信息',
    selectProcedureMethod: '选择过程方法',
    platformID: '平台ID',
    takeAwaySetup: '外卖设置',
    deliveryAdvanceOrderDays: '提前交货订单天数',
    PickUpInterval: '拾取间隔',
    deliverySetup: '速递设置',
    deliveryMiles: '配送里程',
    deliveryFee: '速递费',
    deliveryDesc: '速递说明',
    dineInUnassigned: '堂食自取',
    deliveryMinAmount: '起送金额',
    addAddress: '添加地址',
    deleteAddressTips: '删除这个地址?',
    placeholderApt: '输入详细地址',
    placeholderReceiver: '输入收货人',
    placeholderPhone: '输入手机号码',
    receiver: '收货人',
    currentAmount: '当前积分',
    spend: '立即使用',
    myPrivilege: '我的礼遇',
    myCoupons: '我的优惠券',
    used: '已使用/过期',
    dollarsTitle: '代币',
    brands: '品牌',
    seeAll: '查看所有',
    people: '人',
    latestUpdate: '最新更新时间: ',
    redemptionPeriod: '兑换优惠券日期至: ',
    couponExpiryDate: '优惠券使用日期至: ',
    useNow: '立即使用',
    couponNote: '优惠详情',
    couponUsed: '已使用',
    couponExpired: '已过期',
    url: '地址',
    otherOrders: '其他订单',
    share: '分享',
    route: '路径',
    applemap: '苹果地图',
    gaodemap: '高德地图',
    googlemap: '谷歌地图',
    noOpenBooking: '这个店铺没有开通预约',
    earnPoints: '获得积分',
    redemption: '赎回',
    noPoint: '对不起！没有数据',
    noDataTips: '对不起！没有数据',
    usePoints: '兑换积分',
    aboutPoints: '关于积分',
    membershipGuide: '会员指南',
    payPoint: '使用积分',
    tggMembers: '{abbreviation}会员',
    myAccount: '我的帐户',
    tggDollars: '{abbreviation}代币',
    tableBooking: '订座',
    dine: '用餐',
    shopping: '购物',
    aboutTGG: '关于{abbreviation}',
    availablePoints: '可用积分',
    rewardStatus: '奖励规则',
    pointsEqDollar: '1积分 = 1 {dollar}',
    dollarEqPoints: '1 {dollar} = HK$1',
    minimumSpending: '最低支付金额为20 {dollar}',
    Reward: '兑换积分',
    enterPoint: '选择你想兑换积分的数量',
    pointDetail: '*任何外卖订单都不需要服务费\n*积分按等级累加\n*TGG Dollar只适用于电子支付',
    map: '地图',
    all: '全部',
    filters: '筛选',
    cuisine: '菜肴',
    distance: '距离',
    price: '价格',
    seatedTime: '入座时间',
    seatedTableNumber: '桌号',
    headcount: '人数',
    referenceNumber: '订单编号',
    qrcodeShoppingResultTitle: '订单',
    submitSuccess: '订单已提交',
    newItems: '新增餐点',
    pendingItems: '待处理',
    submittedItems: '已提交',
    orderNow: '立即下单',
    addShoppingCart: '加入购物车',
    qrcodeInvalid: '二维码已失效，如需就餐请联系餐厅职员',
    recentAcitivities: '近期活动',
    nextTier: '升级金额',
    moneyPoint: '每花{money}=赢得{point}分',
    allBrands: '全部品牌',
    comingSoon: '敬请期待...',
    payBill: '支付',
    description: '简介',
    myBookings: '我的预约',
    myQueueings: '我的排队',
    myRecords: '我的记录',
    myFavorites: '我的收藏',
    myAddress: '我的地址',
    myPayments: '我的支付',
    viewShoppingCart: '查看购物车',
    redeemSucceeded: '兑换成功',
    pointBalance: '积分余额',
    storeCredit: '店铺积分',
    pointTip: '你已从余额中兑换{integral}积分',
    redeemTip: '使用{abbreviation}积分探索独家产品和电子商店的特权',
    birthdayTip: '与我们分享您的生日，享受生日惊喜！',
    supplierOrderfor: '选择下单店铺',
    buyerUserID: '您的用户编号是',
    buyerUserTips: '您暂时并没有任何相关店铺，请联系或等待\n',
    buyerUserContact: '团队跟进！',
    register: '注册',
    orderSynchronized: '订单正在同步中',
    tryAgain: '请稍后再试',
    retry: '重试',
    selectDate: '选择出生日期',
    accumulatedSpendings: '总消费金额={amount}',
    spendMoney: '消费',
    upgradedTo: '即可升级为',
    highestLevelTips: '您现在已经升级到最高级别！',
    registeredAndSpent: '注册 & 消费',
    spentYear: '1年内累计消费:',
    registerAsMember: '登记成为会员',
    loginUser: '登入用户',
    scanner: '扫描二维码',
    earnPointsFail: '二维码无效或者已过期',
    earnPointsSuccess: '成功',
    membershipExpire: '将于{date}到期',
    district: '地区',
    members: '会员',
    everySpending: '每次消费',
    asap: '立即',
    canSchedule: '预定',
    notSchedule: '无法预定',
    selectPageextra: '有会员卡, 但没有账户?',
    placeClickHere: '请点击这里',
    receiveInformation: '是的！我想收到有关优惠、活动及最新消息之讯息',
    differentShippingMethodTips: '你已选择了不同的就餐方式,若要继续,你的购物车将会被清空',
    itemsTipsTitle: '你是否合乎法定年龄购买受管制物品',
    itemsTipsTitleContent: '受年龄限制的物品(包括酒精及烟草)不得向未满18岁的人士出售,取餐时,你需出示有效身份证明文件',
    confirmOverAge: '同意，已满18岁',
    currentLevelTitile: '您现在的等级',
    myProfile: '我的资料',
    depositeRequired: '餐厅资讯',
    dollarsBalance: 'Dollars 余额',
    shareVia: '分享',
    copy: '复制',
    copySuccessMsg: '复制成功',
    shareSuccessMsg: '分享成功',
    recentOrders: '近期订单',
    seeAllSuppliers: '查看所有供应商',
    searchByNames: '按名称搜索...',
    showStoreTotal: '展示 {total} 结果',
    deliverySchedule: '交货时间表',
    freeDelivery: '免费送货',
    freeDeliveryAbove: '{amount}以上免费送货',
    remove: '删除',
    shippingCarNotePlaceholder: '请留下您的额外需求或备注信息',
    remark: '备注',
    enterVerifyCode: '请输入有效的验证码',
    showQRTip: '收货时显示二维码',
    resendCode: '重发验证码',
    expressOrderTitle: '快速订单',
    searchItems: '搜索商品...',
    inventoryOptions: '库存选项',
    addToExpressOrder: '添加到快速订单',
    defaultRecorder: '默认再订购金额',
    addItem: '+ 添加商品',
    invalidToken: '请再次登录',
    signCancel: '取消',
    logInAgain: '确认',
    tips: '提示',
    registerDobMsg: '请提供真实的生日信息以获取生日惊喜！',
    resendCodeTimer: '重发验证码 ({time}秒)',
    reminder: '提示',
    agree: '同意',
    viewOrders: '查看订单',
    newPassword: '请输入新密码',
    passwordLength: '为保证您的账户安全，请输入六位数或以上的密码',
    enterPayment: '输入支付金额',
    checkOut: '付款',
    paymentNotOpened: '该店铺未开启付款功能',
    mobileRepeat: '该手机号已被注册，请尝试登录。如果您忘记密码，可通过重置密码找回账户。',
    sendCode: '发送验证码',
    storeNoBusinessTime: '店铺不在营业时间',
    noPointsAvailable: '暂无可兑换的积分',
    expiryDate: '有效日期',
    alreadySoldOut: '已售罄，请选择其他产品',
    paymentConfirmation: '确认支付',
    pointTipCustom: '您已从积分余额中兑换{loyalty}{abbreviation} Dollar',
    changePasswordSuccessfully: '更新成功，请使用新密码登入',
    passwordToShort: '为了您的账户安全，密码不能短于6位，请使用更长的密码。',
    invalidAgain: '验证码或者手机无效，请重试。',
    unknownStore: '店铺信息错误！',
    registered: '此电话号码已被注册，如果您忘记密码，您可以尝试登录或重置密码',
    invalidCode: '验证码无效，请检查',
    encryptionPassword: '重置密码失败，请重试',
    loginAlreadyInUse: '登录名不可用，请使用其它登录名',
    invalidEmailFormat: '电子邮件：{failed}格式错误',
    createUserFailed: '创建用户失败',
    serviceUnavailable: '服务暂时不可用，请稍后再试',
    failedToCreateLogin: '在更新用户登录时创建用户失败并出现错误',
    saveCreateUserFailed: '创建用户在保存手机时失败并出现错误',
    saveCreateLinkedFailed: '账号创建用户失败，创建客户失败',
    emailIsUser: '邮件：{failed}已被使用',
    createEmailFailed: '邮件创建账号失败，错误为：{failed}',
    updateVerifyCodeFailed: '更新验证码状态失败，错误为：{failed}',
    createPresenterFailed: '创建用户失败',
    loginFailed: '获取用户信息失败，请重新登录',
    currentPtsCustom: '当前{abbreviation}积分',
    pointsCustom: '{abbreviation}积分',
    payPointCustom: '使用{abbreviation}积分',
    earnPointsCustom: '获得{abbreviation}积分',
    available: '可预定',
    unavailable: '预定已满',
    purchaseOrderTitle: '采购订单记录',
    viewPurchaseOrder: '订单记录',
    goodReceivedNotesTitle: '收货单',
    noPurchasrOrderTip: '没有采购单',
    purchaseOrder : '采购单',
    alert: '警告',
    purchaseOrderCancelTip: '是否确实要取消此采购订单？',
    accountManager: '客户经理',
    expectedDeliveryDate: '预计交货日期',
    itemQuantity: '项目数量',
    serviceFee: '服务费',
    qty: '数量',
    code: '编码',
    receive: '收货',
    remarkPlaceholder: '添加备注（请准时发货等）',
    supplierName: '供应商名称',
    receivedOn: '接收时间',
    deliveryReceived: '交货时间',
    receivedBy: '接收人',
    poCancelled: '已取消',
    received: '已接收',
    created: '已创建',
    accept: '已接受',
    orderQuantity: '订货量',
    goodReceivedNoteCancelTip: '您确定要取消此收货单吗？',
    goodReceivedNoteReceiveTip: '您确定要接收此收货单货物吗？',
    purchaseOrderDetail: '采购单详情',
    create: '创建',
    createReceive: '创建并接收',
    deliveredQuantity: '交付数量',
    fulfillAll: '完成所有',
    newGoodsReceivedNote: '新建收货单',
    dDollars: 'Dollars',
    outOfStock: '此商品缺货',
    enterPromotionCode: '请输入有效的折扣码',
    readAgree: '我已阅读',
    nowItemsCounts: '{count} 商品',
    clearShoppingCartTip: '请确认是否要删除购物车中的所有产品',
    leaveTheShoppingCartTip: '您在此店铺的订单尚未完成，确定要离开此页面吗？\n *您的物品将保存在购物车中。',
    moreThanOneStoresTip: '您添加了超过1家店铺的商品，您确定要结账吗？',
    stay: '停留',
    leave: '离开',
    editCart: '编辑',
    usePointTip: '使用积分兑换 {name} Dollars 后, 将无法退款',
    usePointRedeem: '兑换',
    showRedeemable: '可兑换',
    redeemWidth: '换购：',
    redeemNow: '立即兑换',
    confirmRedeemTips: '{name}一经使用，不可退还。',
    return: '退货',
    newReturnNote: '新建退货单',
    returnNotesTitle: '退货单',
    returned: '已退货',
    returnNoteCancelTip: '您确定要取消此退货单吗？',
    returnNoteReceiveTip: '您确定要退回此退货单货物吗？',
    receiveTip: '您无法“接收”此订单，因为它尚未“接受”。',
    deferredInvoices: '延期订单',
    thisWeek: '本周',
    thisMonth: '本月',
    monthAgo: '一个月前',
    invoices: '订单',
    lastInvoiceDate: '最后下单日期',
    payAll: '全部付清',
    viewDetails: '查看详情',
    othersProducts: '其他产品',
    payNow: '现在付款',
    backDataTips: '请检查采购内容，确认后将不能修改',
    openInWeChatTips: '不支持微信扫描订购。请使用手机扫描仪扫描二维码',
    keepOrderInfoTips: '请保留此订单号页面作取餐用，多谢！',
  },
  pay: {
    oePay: '八达通',
    weChatPay: '微信支付',
    applePay: 'Apple Pay',
    googlePay: 'Google Pay',
    alipay: '支付宝',
    alipayHK: '支付宝香港',
    tapGoPay: '拍住赏',
    unionPay: '云闪付',
    cardEnding: '卡尾号',
    giftCard: '礼品卡',
    creditCard: '信用卡',
    deferredBilling: '账期付款',
    cashOnDelivery: '货到付款',
    paymentFailed: '交易失败，请输入正确数据并重试',
  },
}
