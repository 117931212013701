import React from 'react';
import { PayTypes } from '@/lib/payment';
import { getIntl } from '../App/App';

interface IPaymentMethodPanelProps {
  data: string[];
  className?: string;
}

const PaymentMethodPanel: React.FC<IPaymentMethodPanelProps> = props => {
  const { className, data } = props;
  const payment = [];
  if (data.includes(PayTypes.VISA || PayTypes.MASTER || PayTypes.JCB || PayTypes.AMEX || PayTypes.UNION_PAY)) {
    payment.push(getIntl().pay.creditCard)
  }
  if (data.includes(PayTypes.APPLE_PAY)) {
    payment.push(getIntl().pay.applePay)
  }

  if (data.includes(PayTypes.ALIPAY_HK)) {
    payment.push(getIntl().pay.alipayHK)
  }

  if (data.includes(PayTypes.TAP_GO)) {
    payment.push(getIntl().pay.tapGoPay)
  }
  if (data.includes(PayTypes.UNION_PAY)) {
    payment.push(getIntl().pay.unionPay)
  }
  if (data.includes(PayTypes.ALIPAY)) {
    payment.push(getIntl().pay.alipay)
  }
  if (data.includes(PayTypes.STORE_CREDIT)) {
    payment.push(getIntl().pay.giftCard)
  }
  if (data.includes(PayTypes.DEFERRED_BILLING)) {
    payment.push(getIntl().pay.deferredBilling)
  }
  if (data.includes(PayTypes.CASH_ON_DELIVERY)) {
    payment.push(getIntl().pay.cashOnDelivery)
  }
  return (
    <div className={`payment-method-wrap ${className}`}>
      <div className="title">{getIntl().page.paymentMethod}</div>
      <div className="payment-method-item">
        {
          payment.length > 0 && payment.map((item, index) => {
            return (
              <span key={index}>{item}{index !== payment.length - 1 ? ', ' : ''}</span>
            )
          })
        }
      </div>
    </div>
  );
}

export default PaymentMethodPanel;
